<template>
  <div class="m-fleetProcess">
    <div>
      <div class="fleetInfo" v-for="(items, index) in orderList" :key="index">
        <div class="processHeader" @click="items.isShow = !items.isShow">
          <div class="headerInfo">
            <p class="headTitle"><span>酒店：</span><span>{{items.title}}</span></p>
          </div>
          <div class="retract">
            <svg class="icon" aria-hidden="true" v-show="items.isShow">
              <use xlink:href="#iconarrow-up"></use>
            </svg>
            <svg class="icon" aria-hidden="true" v-show="!items.isShow">
              <use xlink:href="#iconarrowdown"></use>
            </svg>
          </div>
        </div>
        <div class="processBody" :class="items.isShow ? 'showBody' : 'hiddenBody'">
          <template v-for="(item, index) in items.list">
            <div class="bodyItem" v-if="!(item.supplierType=='offLine' && item.nodeCode=='SupplierUploadInvoice')" :class="item.createdOn ? 'complete' : ''" :key="index">
              <div class="col-2">
                <p class="time" v-if="item.createdOn">{{item.createdOn | formatDate('MM-dd hh:mm')}}</p>
                <p v-else class="time" style="color:#999">未生成</p>
              </div>
              <div class="col-8">
                <p class="title">
                  <span class="icon-radius">1</span>
                  <span>{{item.nodeName}}</span>
                </p>
                <div class="borderLeft" :class="items.list.length == index + 1 ? 'borderNone' : ''">
                  <p><span>处理人：</span><span>{{item.dspName}}</span></p>
                  <p><span>完成时间：</span><span>{{item.doneOn | formatDate('yy-MM-dd hh:mm:ss')}}</span></p>
                </div>
              </div>
              <div class="clear"></div>
            </div>
          </template>
          <div class="clear"></div>
        </div>
        <div style="width: 100%;height: 8px;background: #F5F5F5;" v-if="orderList.length > 1 && index != orderList.length-1"></div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderInfo: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      orderList: []
    }
  },
  computed:{
  },
  watch: {
    orderInfo () {
      let list = JSON.parse(JSON.stringify(this.orderInfo))
      this.orderList = list
    }
  },
  created() {
    let list = JSON.parse(JSON.stringify(this.orderInfo))
    this.orderList = list
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.m-fleetProcess{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-top: .44rem;
}
.fleetInfo {
  background: #ffffff;
}
.processHeader {
  padding: 0.1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.headTitle {
  text-align: left;
  span{
    color: #13161B;
    line-height: .32rem;
    font-size: .16rem;
    font-weight: bold;
  }
}
.headInfo {
  color: #999;
  font-size: .145rem;
}
.clear {
  clear: both;
}
.icon {
  color: #cccccc;
  font-size: .2rem;
}
.time{
  width: 50px;
  float: right;
  margin-right: 20px;
}
.col-2 {
  width: 20%;
  float: left;
  position: relative;
}
.col-8 {
  width: 80%;
  float: left;
  position: relative;
  text-align: left;
}
.icon-radius {
  color: transparent;
  position: absolute;
  width: 10px;
  height: 10px;
  left: -5px;
  background-color: #ccc;
  border-radius: 50%;
  top: 7px;
}
.icon-radius:before {
  content: '';
  width: 20px;
  height: 20px;
  opacity: 0.2;
  background-color: #ccc;
  position: absolute;
  border-radius: 50%;
  top: -5px;
  left: -5px;
}
.complete {
  .icon-radius {
    background-color: var(--themeColor);
  }
  .icon-radius:before {
    background-color: var(--themeColor);
  }
}
.title {
  margin-left: 20px;
  font-size: 16px;
  font-weight: 600;
}
.borderLeft {
  border-left: 1px dashed #666;
  padding: 0px 0 10px 20px
}
.bodyItem {
  padding-top: 15px;
  color: #999999;
  font-size: 14px;
}
.bodyItem.complete {
  color: #333;
}
.complete .title{
  color: var(--themeColor);
}
.processBody.showBody {
  max-height: 1000px;
  transition: max-height .5s;
  overflow: hidden;
}

.processBody.hiddenBody {
  max-height: 0px;
  transition: max-height .5s;
  overflow: hidden;
}
.borderNone {
  border: none
}
</style>
