<template>
  <section>
    <sm_header title='酒店进程' :smHleftEvent='closeWebview'></sm_header>
    <hotel-process :orderInfo="orderList"></hotel-process>
  </section>
</template>
<script>
import hotelProcess from './hotelProcess'
import generalBridge from "@/plugin/generalBridge.js";
export default {
  components: {
    hotelProcess
  },
  data () {
    return {
      fromPage: false,
      pageTitle: '酒店进程',
      ProcessName: '',
      orderList: []
    }
  },
  computed: {
  },
  created() {
    this.initFromPage()
  },
  methods: {
    // header
    closeWebview() {
      if (this.fromPage) {
        this.$router.back();
      } else {
        generalBridge.propy.call(this);
      }
    },
    initFromPage() { // 初始化fromPage
      let fromPage = this.$route.query;
      this.fromPage = fromPage.fromPage ? fromPage.fromPage : false;
      this.loadData()
    },
    // 页面初始化
    async loadData () {
      let params = {
        ProposalId:  this.$SmartStorage.get('eventData').proposalId || '', // '0abeda7e-d97b-40fc-a9b8-8b0c55c5bcc7'//,
        OrderId: this.$SmartStorage.get('orderId') || null,
        TypeDictVal: 'V2'
      }
      let paramsLog = {
        ProposalId: this.$SmartStorage.get('eventData').proposalId || '', // 0abeda7e-d97b-40fc-a9b8-8b0c55c5bcc7//,
        OrderId: this.$SmartStorage.get('orderId') || null,
        FlowCodes: this.$SmartStorage.get("tenant") == 'abbvie' ? ['venue.supplier.quote'] : ['venue.supplier.quote','venue.supplier.settle']
      }
      let res =await this.$service.QueryOrders(params)
      let resLog =await this.$service.SupplierTaskLogs(paramsLog)
      let dataListCopy = await this.getData(res.content, resLog.content)
      this.orderList = dataListCopy
    },
    getData (val1, val2) {
      let dataListCopy = []
      val1.forEach((item) => {
        let list = []
        val2.forEach((items) => {
          if (item.supplierId === items.id) {
            list.push(items)
          }
        })
        let listCopy = {}
        listCopy.list = list
        listCopy.title = item.name
        listCopy.isShow = true
        dataListCopy.push(listCopy)
      })
      return dataListCopy
    }
  }
}
</script>
<style scoped>
.supplierHead {
  font-size: .22rem;
  font-weight: 500;
  text-align: left;
  padding: 0 0.2rem;
}
</style>
